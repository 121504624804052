<template>
    <multiselect
        v-model="internalValue"
        :options="options"
        :multiple="false"
        trackBy="id"
        :label='labelField'
        placeholder=""
        select-label=""
        selected-label=""
        deselect-label=""
        deselected-label=""
        @input="onChange"
        :disabled="disabled"
        :show-no-results="false"
        open-direction="bottom"
    >
    </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import Multiselect from 'vue-multiselect';

    import { MARKUP_PRESETS_ENDPOINT } from '../../utils/endpoints';

    export default {
        components: {
            Multiselect,
        },
        props: {
            value: {},
            nomenclatureId: {},
            disabled: { default: false },
            label: { type: String, default: 'name' }
        },
        computed: {
            labelField() {
                return this.label;
            }
        },
        watch: {
            value() {
                this.internalValue = this.value;
            },
            nomenclatureId() {
                this.$emit( 'input', null );
                this.loadOptions();
            }
        },
        mounted() {
            this.loadOptions();
        },
        data() {
            return {
                internalValue: this.value,
                options: []
            }
        },
        methods: {
            onChange() {
                this.$emit( 'input', this.internalValue );
            },
            loadOptions() {
                this.options = [];

                if ( !this.nomenclatureId ) {
                    return null;
                }

                let params = {
                    filters: {
                        limit: -1,
                        nomenclature: { id: this.nomenclatureId }
                    }
                };

                this.$http
                    .get( MARKUP_PRESETS_ENDPOINT, { params } )
                    .then( response => this.options = response.data.data.data );
            }
        }
    }
</script>
