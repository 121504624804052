<template>
    <select class="form-control" @change="onChange" v-model="internalValue" :disabled="disabled">
        <option v-for="reason in reasons" v-bind:key="reason.id" :value="reason.id" :disabled="!reason.is_selectable">
            {{ reason.comment }}
        </option>
    </select>
</template>

<script>
    import { PRODUCTION_PLAN_REASONS_ENDPOINT } from '../../utils/endpoints'

    export default {
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value() {
                this.internalValue = this.value ? this.value.id : null
            }
        },
        data() {
            return {
                reasons: [],
                internalValue: this.value ? this.value.id : null
            }
        },
        methods: {
            load() {
                this.$http.get(PRODUCTION_PLAN_REASONS_ENDPOINT, {
                      params: {
                          limit: 1000
                      }
                    })
                    .then(response => {
                        this.reasons = response.data.data.data
                    }).catch(response => this.$root.responseError(response, 'Ошибка загрузки причин плана производства'))
            },
            onChange() {
                this.$emit('input', this.reasons.find(reason => reason.id === this.internalValue))
            },
        },
        mounted() {
            this.load()
        }
    }
</script>
