<template>
    <div class="container">
        <b-card>
            <div class="row">
                <b-col md="12" class="text-center">
                    <h3 v-if="id">План производства #{{ id }}</h3>
                    <h3 v-else>Новый план производства</h3>
                </b-col>
            </div>
            <div class="row">
                <b-col md="12">
                    <errors-bag-list></errors-bag-list>
                </b-col>
            </div>
            <div class="row">
                <b-col md="12" v-if="successMessage">
                    <div class="alert alert-success">{{ successMessage }}</div>
                </b-col>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <b-form-group description="Отгрузка">
                        <date-time-picker
                            locale='ru'
                            input-size='sm'
                            position='bottom'
                            label='Отгрузка'
                            id='production_plan_datetime'

                            :no-label='true'
                            :no-header='true'
                            :no-button-now='true'
                            :no-clear-button='true'
                            :only-date='true'

                            format='YYYY-MM-DD'
                            formatted='YYYY-MM-DD'

                            v-model='item.date'
                        />
                    </b-form-group>
                </div>
                <div class="col-md-10">
                    <div class="alert alert-info">
                        <i class="fas fa-info-circle"></i>
                        Указывайте ту дату отгрузки, к которой относится этот план. <br>
                        <p style="padding-left: 18px;">
                            Например, если РЦ недосчитался лотков после вчерашней отгрузки и вам нужно их доготовить,
                            указывайте вчерашнюю дату <br>
                            Если вам нужно доготовить п\ф для сегодняшней отгрузки, указывайте сегодняшнюю дату <br>
                            Если вам нужно доготовиь п\ф для завтрашней отгрузки, указывайте завтрашнюю дату
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <b-col md="12">
                    <b-form-group description="Номенклатура">
                        <nomenclature-input :disabled="!!id" v-model="item.nomenclature"></nomenclature-input>
                    </b-form-group>
                </b-col>
            </div>
            <div class="row">
                <b-col md="12">
                    <b-form-group description="Причина создания">
                        <production-plan-reason-input v-model="item.reason">
                        </production-plan-reason-input>
                    </b-form-group>
                </b-col>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form-group description="Площадка">
                        <area-select v-model="item.area_id" :disabled="!isAdditionalPlan && !item.is_available_to_overflow_of_areas"/>
                    </b-form-group>
                </div>
            </div>
            <div class="row" v-if="isAdditionalPlan">
                <b-col md="12">
                    <b-form-group description="Комментарий">
                        <b-form-textarea v-model="item.commentary" rows="8"/>
                    </b-form-group>
                </b-col>
            </div>
            <div class="row">
                <b-col md="12" v-if="item.nomenclature">
                    <h4 class="text-center">Маркировки плана</h4>

                    <div v-for="( planMarkupPreset, index ) in item.markupPresets">
                        <div class="row">
                            <b-col md="11">
                                <b-form-group :description="`Пресет маркировки #` + ( index + 1 )">
                                    <markup-preset-input
                                        label='pack_label_name'
                                        v-model="planMarkupPreset.markupPreset"
                                        :nomenclature-id="item.nomenclature.id"
                                    ></markup-preset-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="1">
                                <button class="btn btn-danger" @click="deleteMarkupPreset( index )">
                                    <i class="fas fa-minus-circle"></i>
                                </button>
                            </b-col>
                        </div>

                        <div class="row">
                            <b-col md="12">
                                <b-form-group :description="`Количество #` + ( index + 1 )">
                                    <input
                                        type="number"
                                        class="form-control"
                                        v-model="planMarkupPreset.count"
                                    />
                                </b-form-group>
                            </b-col>
                        </div>
                    </div>
                    <b-button variant="info" style="color:#fff;" v-if="item.nomenclature" @click="addMarkupPreset()">
                        Добавить маркировку
                    </b-button>
                </b-col>
            </div>
            <div class="row mt-2">
                <div class="col-md-6">
                    <b-button variant="secondary" @click="$router.go(-1)">Назад</b-button>
                </div>
                <b-col md="6" class="text-right">
                    <b-button variant="success" v-if="!id" @click="createProductionPlan()">Создать</b-button>
                    <b-button variant="success" v-else @click="updateProductionPlan()">Сохранить</b-button>
                </b-col>
            </div>
            <b-row class="mt-2">
                <b-col md="12">
                    <div class="alert alert-warning">
                        <b>* Примечение:</b><br/>
                        <ul>
                            <li>Нельзя изменять номенклатуру и крайний срок, если у плана уже есть акты выпуска</li>
                            <li>При удалении маркировки, маркировка не удаляется, а обнуляется</li>
                            <li>
                                Сохранение плана возможно только при <b>ВСЕХ</b> установленных значениях маркировки для
                                добавленных позиций, либо при <b>ОТСУТСТВИИ</b> маркировок для <b>ВСЕХ</b> позиций.
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import moment from 'moment';

import MultiSelect from 'vue-multiselect';

import DateTimePicker from 'vue-ctk-date-time-picker';

import ErrorsBagList             from '@components/_common/ErrorsBagList';
import NomenclatureInput         from '@components/_common/NomenclatureInput';
import MarkupPresetInput         from '@components/_common/MarkupPresetInput';
import ProductionPlanReasonInput from '@components/_common/ProductionPlanReasonInput';
import {AreaSelect}                from '@components';

import ErrorsBag                   from '@utils/errorsBag';
import {PRODUCTION_PLANS_ENDPOINT} from '@utils/endpoints';

const AUTO_PRODUCTION_PLAN_REASON = 'auto';
const AUTO_ADDITIONAL_PRODUCTION_PLAN_REASON = 'auto_additional';

export default {
    name: 'ProductionPlanForm',
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            ErrorsBag,

            successMessage: null,

            item: {
                date: null,
                reason: null,
                commentary: null,
                markupPresets: [],
                nomenclature: null,
                area_id: null,
            },

            nomenclatures: [],
            errors: {
                date: null,
                reason: null,
                commentary: null,
                nomenclature: null,
                area_id: null,
            },
        };
    },
    computed: {
        isAdditionalPlan() {
            return this.item.reason
                && this.item.reason.name !== AUTO_PRODUCTION_PLAN_REASON
                && this.item.reason.name !== AUTO_ADDITIONAL_PRODUCTION_PLAN_REASON;
        },

        preparedItem() {
            const productionPlan = {
                markup_presets: [],
                reason: this.item.reason,
                commentary: this.item.commentary,
                nomenclature: this.item.nomenclature,
                date: this.item.date ? moment(this.item.date).format('YYYY-MM-DD') + ' 20:00' : null,
                area_id: this.item.area_id,
            };

            this.item.markupPresets.forEach((planMarkupPreset) => {
                productionPlan.markup_presets.push({
                    id: planMarkupPreset.id ? planMarkupPreset.id : null,
                    count: planMarkupPreset.count,
                    markup_preset: planMarkupPreset.markupPreset ? {id: planMarkupPreset.markupPreset.id} : null
                });
            })

            return productionPlan;
        }
    },
    components: {
        MultiSelect,
        ErrorsBagList,
        ProductionPlanReasonInput,
        MarkupPresetInput,
        NomenclatureInput,
        AreaSelect,
        DateTimePicker,
    },
    watch: {
        'item.nomenclature.id': {
            deep: true,
            handler(before, after) {
                if (after && before !== after) {
                    this.item.markupPresets = [];
                }
            }
        }
    },
    methods: {
        validation() {
            ErrorsBag.discard();
            this.successMessage = null;

            let errors = false;

            if (!this.item.date) {
                ErrorsBag.add('Поле Отгрузка обязательно для заполнения');
                errors = true;
            }

            if (!this.item.area_id) {
                ErrorsBag.add('Поле Площадка обязательно для заполнения');
                errors = true;
            }

            if (!this.item.nomenclature) {
                errors = true;
                ErrorsBag.add('Поле Номенклатура обязательно для заполнения');
            }

            if (!this.item.reason) {
                errors = true;
                ErrorsBag.add('Поле Причина создания обязательно для заполнения');
            }

            if (this.item.markupPresets.length < 1) {
                errors = true;
                ErrorsBag.add('Добавьте хотя бы одну маркировку');
            }

            let positiveMarkupPresets = false;
            let negativeMarkupPresets = false;

            this.item.markupPresets.forEach((markup) => {
                if (!negativeMarkupPresets && markup.count < 0) {
                    negativeMarkupPresets = true;
                }

                if (!positiveMarkupPresets && markup.count > 0) {
                    positiveMarkupPresets = true;
                }
            });

            if (!positiveMarkupPresets) {
                errors = true;
                ErrorsBag.add('Хотя бы одна маркировка должна иметь количество больше 0');
            }

            if (negativeMarkupPresets) {
                errors = true;
                ErrorsBag.add('Маркировка не должна иметь отрицательное количество');
            }

            if (this.isAdditionalPlan && !this.item.commentary) {
                errors = true;
                ErrorsBag.add('Поле Комментарий обязателен для заполнения');
            }

            return !errors;
        },

        updateProductionPlan() {
            if (!this.validation()) {
                return;
            }

            this.$http
                .put(PRODUCTION_PLANS_ENDPOINT + '/' + this.id, this.preparedItem)
                .then(() => {
                    this.load();
                    this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                })
                .catch(response => ErrorsBag.fill(response.body));
        },

        createProductionPlan() {
            if (!this.validation()) {
                return;
            }

            this.$http
                .post(PRODUCTION_PLANS_ENDPOINT, this.preparedItem)
                .then((response) => {
                    this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                    this.$router.push({
                        name: 'ProductionPlanEdit',
                        params: {id: response.data.data.id}
                    });
                })
                .catch(response => ErrorsBag.fill(response.body));
        },

        addMarkupPreset() {
            let newMarkupPreset = {
                id: null,
                count: 0,
                markupPreset: null
            };

            this.item.markupPresets.push(newMarkupPreset);
        },

        deleteMarkupPreset(index) {
            this.item.markupPresets.splice(index, 1);
        },

        clearForm() {
            ErrorsBag.discard();

            this.item = {
                date: null,
                reason: null,
                commentary: null,
                markupPresets: [],
                nomenclature: null,
            };
        },

        prepareProductionPlan(data) {
            this.item.reason = data.reason;
            this.item.commentary = data.commentary;
            this.item.nomenclature = data.nomenclature;
            this.item.date = moment(data.date).format('YYYY-MM-DDTHH:mm:ss');
            this.item.area_id = data.area_id;
            this.item.is_available_to_overflow_of_areas = data.is_available_to_overflow_of_areas;

            if (data.markupPresets.length <= 0) {
                this.item.markupPresets.push({
                    id: null,
                    count: data.count,
                    markupPreset: null
                });

                return null;
            }

            data.markupPresets.forEach((markupPreset) => {
                let preparedMarkupPreset = {
                    id: markupPreset.pivot.id,
                    count: markupPreset.pivot.count,
                    markupPreset: {
                        id: markupPreset.id,
                        pack_label_name: markupPreset.pack_label_name
                    }
                };

                this.item.markupPresets.push(preparedMarkupPreset);
            });
        },

        load() {
            if (this.id) {
                this.clearForm();
                let params = {with: ['nomenclature', 'markupPresets', 'reason']};

                this.$http
                    .get(PRODUCTION_PLANS_ENDPOINT + '/' + this.id, {params})
                    .then(response => this.prepareProductionPlan(response.data.data))
                    .catch(response => ErrorsBag.fill(response.body));
            }
        }
    },

    mounted() {
        this.load();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
